// Utils for the settings page

// Styles

/**
 * Style used in the autocompletes from the settings page. Makes borders, input and icons white.
 */
export const allWhiteAutocompleteStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white !important', // Set the outline color to white
    },
    '& input': {
      color: 'white !important', // Set the input text color to white
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: 'white !important', // Set the color of the arrow icon to white
    },
    '& .MuiAutocomplete-clearIndicator': {
      color: 'white !important', // Set the color of the "x" icon to white
    },
  },
};

/**
 * Style used for the buttons. Includes normal color, hover color, disabled style.
 */
export const buttonStyle = {
  marginTop: 2,
  marginBottom: 2,
  gridRow: 1,
  width: 350,
  backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
  color: 'white', // Enabled text color
  '&:hover': {
    backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
  },
  '&:disabled': {
    backgroundColor: '#7D8387', // Disabled background color
    color: 'rgba(0, 0, 0, 0.5)', // Disabled text color
  },
};

// Functions

/**
   * Checks if the input for a specific field matches its original default value.
   * @param {String} currentValue - the value that is currently in the field.
   * @param schemaDefaults - the default value from the schema for that specific field.
   * @returns true, if the two values match, false otherwise.
   */
export function fieldMatchesSchema(currentValue, schemaDefaults) {
  const currValueInteger = Number(currentValue);

  if ((currentValue === schemaDefaults) || (!Number.isNaN(currValueInteger) && (currValueInteger === schemaDefaults))) {
    return true;
  }

  return false;
}

/**
 * Returns the correct label style for a specific field in the settings page.
 * @param field - The chosen field's value
 * @param schemaDefault - The default value for the chosen field from the schema
 * @returns default style, if the two fields match, highlighted style, if they differ.
 */
export function generateSettingsLabelStyle(field, schemaDefault) {
  if (fieldMatchesSchema(field, schemaDefault)) {
    return { shrink: true }; // Default style
  }
  return { shrink: true, style: { color: 'green' } }; // Highlighted style
}

/**
 * Returns the correct textfield style for a specific field in the settings page.
 * @param field - The chosen field's value
 * @param schemaDefault - The default value for the chosen field from the schema
 * @returns default style, if the two fields match, highlighted style, if they differ.
 */
export function generateSettingsTextFieldStyle(field, schemaDefault) {
  if (fieldMatchesSchema(field, schemaDefault)) {
    return { gridColumn: 'span 1' }; // Default style
  }
  return {
    gridColumn: 'span 1',
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
      '&:not(.Mui-focused):not(:hover) fieldset': {
        borderColor: 'green',
      },
    },
  }; // Highlighted style
}

/**
 * Generates a tooltip for a given field
 * @param {Object} field - The field to generate a tooltip for
 * @returns {string} - The tooltip
 */
export function generateTooltips(field) {
  return `${field.description}
  \n\nThis input should be of type ${Array.isArray(field.type) ? field.type.join(' or ') : field.type}
  ${field.hasOwnProperty('default') ? `\nDefault value: ${field.default}` : ''}
  ${field.hasOwnProperty('minimum') ? `\nMinimum value: ${field.minimum}` : ''}
  ${field.hasOwnProperty('maximum') ? `\nMaximum value: ${field.maximum}` : ''}
  ${field.hasOwnProperty('enum') ? `\nPossible values: ${field.enum.join(', ')}` : ''}`;
}

/**
 * Checks whether a given object is empty ({}).
 * @param {Object} obj - The object to check
 * @returns {boolean} - True if the object is empty, false otherwise
 */
export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

/**
 * Copies the settings from one object (network, densification_ps, ...) to another,
 * without copying the 'aoi' field. This is used for the settings copying
 * functionality in the settings page.
 * @param {Object} source - The object to copy from
 * @param {Object} destination - The object to copy to
 * @param {boolean} copyAoi - Whether to copy the 'aoi' field or not
 * @returns {Object} - The final object, with settings copied from the source object, with the original 'aoi' field.
 */
export function copyObject(source, destination, copyAoi) {
  const result = {};
  console.log('Copy aoi: ', copyAoi);

  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (key === 'aoi' && copyAoi === false) {
        result[key] = destination[key];
      } else if (key === 'aoi' && copyAoi === true) {
        result[key] = source[key];
      } else if (typeof source[key] === 'object' && source[key] !== null) {
        result[key] = copyObject(source[key], {});
      } else {
        result[key] = source[key];
      }
    }
  }

  return result;
}

/**
 * Checks whether two date arrays match.
 * @param {Array<String>} dates1 - The first date array
 * @param {Array<String>} dates2 - The second date array
 * @returns {boolean} - True if the two date arrays match, false otherwise
 */
export function datesMatch(dates1, dates2) {
  if (dates1.length !== dates2.length) {
    return false;
  }

  for (let i = 0; i < dates1.length; i += 1) {
    if (dates1[i] !== dates2[i]) {
      return false;
    }
  }

  return true;
}

/**
 * Checks whether two given objects share the same keys.
 * This is used for copying settings from one project to another,
 * to ensure that settings can be copied.
 * @param {Object} obj1 - The first object to compare
 * @param {Object} obj2 - The second object to compare
 * @returns true, if the two objects share the same keys, false otherwise.
 */
export function settingsMatch(obj1, obj2) {
  // console.log('comparing: ', obj1, obj2);
  if (obj1 === null || obj2 === null) {
    return true;
  }

  if (typeof obj1 !== 'object' && typeof obj2 !== 'object') {
    return true;
  }

  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    // if both are object types compare all keys
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    return (obj1Keys.length == obj2Keys.length)
            && obj1Keys.every((key) => obj2Keys.includes(key) && settingsMatch(obj1[key], obj2[key]));
  }

  // Empty object shares the same keys as any other type
  if ((typeof obj1 === 'object') && (Object.keys(obj1).length < 1)) {
    return true;
  }

  return Object.keys(obj2).length < 1;
}
