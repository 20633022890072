import * as React from 'react';
import {
  Box, Autocomplete, TextField, Button, CircularProgress, Typography,
} from '@mui/material';
import Sidebar from '../components/Sidebar';
import {
  allWhiteAutocompleteStyle,
  buttonStyle,
} from '../util/settingsUtils';
import '../styles/Launchpad.css';
import StackProgressBox from '../components/StackProgressBox';
import { loadProjectStatus, loadProjectAOIs, checkProjectSettings } from '../util/launchpadRequests';
import InactiveTimer from '../components/inactiveTimer';
import logout from '../util/logout';
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorScreen from './ErrorScreen';

// Hooks
import useAllProjects from '../hooks/useAllProjects';
import useProjectStacks from '../hooks/useProjectStacks';
import useError from '../hooks/useError';

export default function NewLaunchpad() {
  const { projects } = useAllProjects();
  const [chosenProject, setChosenProject] = React.useState('');
  const { projectStacks } = useProjectStacks(chosenProject);
  const [projectAOIs, setProjectAOIs] = React.useState({});
  const [projectErrors, setProjectErrors] = React.useState({});
  const [progress, setProgress] = React.useState({});
  const [isLoadButtonPressed, setIsLoadButtonPressed] = React.useState(false);
  const [isProjectLoading, setIsProjectLoading] = React.useState(false);
  const { error, handleError } = useError();

  /**
   * Handles errors from the StackProgressBox component.
   * @param {String} err - the error message.
   * @param {String} detailedError - the error details.
   */
  const handleStackProgressBoxError = (err, detailedError) => {
    console.log('Received error: ', err);
    console.log('Details: ', detailedError);
    handleError(err, detailedError);
  };

  /**
   * Checks for inactivity every 60 minutes.
   */
  React.useEffect(() => {
    const timer = new InactiveTimer({
      timeout: 3600,
      onTimeout: () => {
        logout(); // If timer expires when the user is in the app
      },
    });

    return () => {
      timer.cleanup();
    };
  }, []);

  const loadProject = async () => {
    if (isProjectLoading) return; // prevent request flooding
    setIsProjectLoading(true);

    try {
      const aois = await loadProjectAOIs(chosenProject);
      const errors = await checkProjectSettings(chosenProject);
      const loadedProgress = await loadProjectStatus(chosenProject);

      setProjectAOIs(aois);
      setProjectErrors(errors);
      setProgress(loadedProgress);
      setIsLoadButtonPressed(true);
      setIsProjectLoading(false);
    } catch (err) {
      handleError(err.message, err.detailedMessage);
    } finally {
      setIsProjectLoading(false);
    }
  };

  const drawerWidth = 240;

  if (error.short !== '') {
    return <ErrorScreen error={error.short} errorMessage={error.details} />;
  }

  return (
    <ErrorBoundary>
      <div id="launchpad-background">
        <Box sx={{ display: 'flex' }}>
          { /* Sidebar component. */ }
          <Sidebar />

          { /* Main content. */}
          <Box
            sx={{
              flexGrow: 1,
              p: 3,
              m: 1,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h1> BETA Launchpad screen </h1>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={projects}
              autoHighlight
              sx={{ width: 'calc(50% - 10px)', marginTop: '10px' }}
              onChange={(event, value) => {
                console.log('New project: ', value);
                setChosenProject(value == null ? '' : value);
                setProgress({});
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project name"
                  InputLabelProps={{
                    shrink: params.inputProps.value || chosenProject !== '',
                    style: {
                      color: 'white',
                    },
                  }}
                  sx={allWhiteAutocompleteStyle}
                />
              )}
            />

            <Button
              variant="contained"
              disabled={chosenProject === ''}
              sx={{ width: '48%', ...buttonStyle }}
              onClickCapture={() => {
                loadProject();
              }}
            >
              Load Project
            </Button>

            {isProjectLoading && (
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <CircularProgress />
                <Typography sx={{ marginLeft: '10px' }}>Loading...</Typography>
              </Box>
            )}

            {progress && Object.keys(progress).length > 0 && (
              <Button
                variant="contained"
                disabled={chosenProject === ''}
                sx={{ width: '48%', ...buttonStyle }}
                onClickCapture={() => {
                  navigator.clipboard.writeText(JSON.stringify(progress, null, 2));
                }}
              >
                Troubleshooting Data
              </Button>
            )}

            {isLoadButtonPressed && progress && Object.keys(progress).length > 0 && (
              projectStacks.map((stack) => (
                <StackProgressBox
                  key={stack.id}
                  project={chosenProject}
                  stack={stack.name}
                  errors={projectErrors[stack.name]}
                  aois={projectAOIs[stack.name]}
                  progress={progress}
                  setter={setProgress}
                  onError={handleStackProgressBoxError}
                />
              ))
            )}

          </Box>
        </Box>
      </div>
    </ErrorBoundary>
  );
}
