/**
 * Computes the correct UI options (button text, loading status, etc.) for a given project status.
 * @param {Object} projectStatus - The project status object, returned from the backend.
 */
export default function projectStatesMap(projectStatus) {
  console.log('projectStatus: ', projectStatus);

  // Base options for the UI
  let basePrep = { buttonText: 'Run', buttonDisabled: false, progressBar: false };
  let baseNetwork = { buttonText: 'Run', buttonDisabled: false, progressBar: false };
  let baseDensification = { buttonText: 'Run', buttonDisabled: false, progressBar: false };

  try {
    const prep = projectStatus.prep.status;
    const network = projectStatus.network.status;
    const densification = projectStatus.densification.status;

    // Stack transfer is running
    if (['STARTED', 'RECEIVED', 'PENDING', 'RETRY', 'ALREADY_RUNNING'].includes(prep)) {
      return {
        prep: { buttonText: 'Kill', buttonDisabled: false, progressBar: true },
        network: { ...baseNetwork, buttonDisabled: true },
        densification: { ...baseDensification, buttonDisabled: true },
      };
    }

    if (['SUCCESS', 'FAILURE', 'REVOKED', 'MISSING', 'ERROR'].includes(prep)) {
      basePrep = { buttonText: 'Run', buttonDisabled: false, progressBar: false };
    } else if (prep === 'UPDATE_RUNNING') {
      basePrep = { buttonText: 'Run', buttonDisabled: true, progressBar: true };
    }

    // Densification is running
    if (['STARTED', 'RECEIVED', 'PENDING', 'RETRY'].includes(densification)) {
      return {
        prep: { ...basePrep, buttonDisabled: true },
        network: { ...baseNetwork, buttonDisabled: true },
        densification: { buttonText: 'Kill', buttonDisabled: false, progressBar: true },
      };
    }

    // Network is running
    if (['STARTED', 'RECEIVED', 'PENDING', 'RETRY'].includes(network)) {
      return {
        prep: { ...basePrep, buttonDisabled: true },
        network: { buttonText: 'Kill', buttonDisabled: false, progressBar: true },
        densification: { ...baseDensification, buttonDisabled: true },
      };
    }

    if (network === 'REVOKED' || network === 'FAILED' || network === 'ERROR') {
      baseNetwork = { buttonText: 'Run', buttonDisabled: false, progressBar: false };
      baseDensification = { buttonText: 'Run', buttonDisabled: true, progressBar: false };
    }
  } catch (e) {
    console.log('Error in projectStatesMap: ', e);
  }

  return { prep: basePrep, network: baseNetwork, densification: baseDensification };
}
