import schema from './schema.json';
import { extractPoints, checkPolygonOverlap } from './geometry';

/**
   * Handler for clicking on the dates
   * @param date - the date that was clicked
   * @param setDates - the function that updates the dates state
   * @param setFilteredDates - the function that updates the filteredDates state
   */
export function handleDateClick(date, setDates, setFilteredDates) {
  // change the checked state of the date and save it to the state
  setFilteredDates((prevFilteredDates) => prevFilteredDates.map((d) => {
    if (d.date === date) {
      return { ...d, checked: !d.checked };
    }
    return d;
  }));

  setDates((prevDates) => prevDates.map((d) => {
    if (d.date === date) {
      return { ...d, checked: !d.checked };
    }
    return d;
  }));
}

/**
 * Handler for clicking on the "Select all" button.
 * Sets all filtered dates to checked and updates the state.
 * @param dates - all the available dates.
 * @param setDates - the function that updates the dates state.
 * @param filteredDates - the dates that are currently filtered.
 * @param setFilteredDates - the function that updates the filteredDates state.
 */
export function handleSelectAllDates(dates, setDates, filteredDates, setFilteredDates) {
  setFilteredDates((prevFilteredDates) => prevFilteredDates.map((d) => ({ ...d, checked: true })));
  setDates((prevDates) => prevDates.map((d) => {
    const filteredDate = filteredDates.find((fd) => fd.date === d.date);
    if (filteredDate) {
      return { ...d, checked: true };
    }
    return d;
  }));
}

/**
 * Handler for clicking on the "Deselect all" button.
 * Sets all filtered dates to unchecked and updates the state.
 * @param dates - all the available dates.
 * @param setDates - the function that updates the dates state.
 * @param filteredDates - the dates that are currently filtered.
 * @param setFilteredDates - the function that updates the filteredDates state.
 */
export function handleDeselectAllDates(dates, setDates, filteredDates, setFilteredDates) {
  setFilteredDates((prevFilteredDates) => prevFilteredDates.map((d) => ({ ...d, checked: false })));
  setDates((prevDates) => prevDates.map((d) => {
    const filteredDate = filteredDates.find((fd) => fd.date === d.date);
    if (filteredDate) {
      return { ...d, checked: false };
    }
    return d;
  }));
}

/**
   * Handler for changing a network-related setting
   * @param e - the event that triggered the change (from this event we can extract the value of the input)
   * @param section - the section of the setting that was changed (e.g. "general", "aps_estimation", etc.)
   * @param key - the key of the setting that was changed
   * @param chosenStackAOI - the AOI of the chosen stack
   * @param networkSettings - the current network settings
   * @param setNetworkSettings - the function that updates the network settings state
   */
export function handleNetworkSettingsChange(e, section, key, chosenStackAOI, networkSettings, setNetworkSettings) {
  const updatedSettings = { ...networkSettings };
  const fieldSchema = schema.properties.network.properties[section].properties[key];

  // The input isn't empty
  if (e.target.value.trim().length !== 0) {
    console.log(`updating: ${section} ${key}`);
    console.log(`new value: ${e.target.value}`);

    // The input is null
    if (e.target.value === 'null') {
      // Input can be null
      if (Array.isArray(fieldSchema.type) && fieldSchema.type.includes('null')) {
        updatedSettings[section][key] = null;
      }

      // Input can't be null
      else {
        e.target.value = updatedSettings[section][key];
        alert('This input can not be null!');
      }
    } else if (e.target.value !== 'null') {
      const floatPattern = /^\d+\.0$/; // matches numbers ending in .0
      const requiredType = Array.isArray(fieldSchema.type) ? fieldSchema.type.filter((type) => type !== 'null')[0] : fieldSchema.type;

      // Boolean
      if (requiredType === 'boolean') {
        if (e.target.value === 'true' || e.target.value === 'false') {
          updatedSettings[section][key] = e.target.value === 'true';
        } else {
          e.target.value = updatedSettings[section][key];
          alert('This input should be a boolean!');
        }
      }

      // Integer
      else if (requiredType === 'integer') {
        const minValue = fieldSchema.minimum;
        const maxValue = fieldSchema.maximum;

        if (key === 'number_of_nearest_neighbors_to') {
          if (Number(e.target.value) <= updatedSettings[section].number_of_nearest_neighbors_from) {
            if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
              // Correct case
              updatedSettings[section][key] = Number(e.target.value);
              e.target.value = Number(e.target.value);
            } else {
              // Out of bounds
              e.target.value = updatedSettings[section][key];
              alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
            }
          } else {
            // number_of_nearest_neighbors_to is smaller than number_of_nearest_neighbors_from
            e.target.value = updatedSettings[section][key];
            alert('This input should be smaller than number_of_nearest_neighbors_from!');
          }
        } else if (key === 'number_of_nearest_neighbors_from') {
          if (Number(e.target.value) >= updatedSettings[section].number_of_nearest_neighbors_to) {
            if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
              // Correct case
              updatedSettings[section][key] = Number(e.target.value);
              e.target.value = Number(e.target.value);
            } else {
              // Out of bounds
              e.target.value = updatedSettings[section][key];
              alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
            }
          } else {
            // number_of_nearest_neighbors_from is bigger than number_of_nearest_neighbors_to
            e.target.value = updatedSettings[section][key];
            alert('This input should be bigger than number_of_nearest_neighbors_to!');
          }
        } else {
          // If it's a number that ends in .0 -> transform to integer
          if (floatPattern.test(e.target.value)) {
            if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
              updatedSettings[section][key] = Number(e.target.value);
              e.target.value = Number(e.target.value);
            } else {
              e.target.value = updatedSettings[section][key];
              alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
            }
          }

          // If it's any other kind of number
          else if (!Number.isNaN(Number(e.target.value))) {
            if (Number.isInteger(Number(e.target.value))) {
              if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
                updatedSettings[section][key] = Number(e.target.value);
              } else {
                e.target.value = updatedSettings[section][key];
                alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
              }
            } else {
              e.target.value = updatedSettings[section][key];
              alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
            }
          }

          // If it's not a number
          else {
            e.target.value = updatedSettings[section][key];
            alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
          }
        }
      }

      // Number
      else if (requiredType === 'number') {
        const minValue = fieldSchema.minimum;
        const maxValue = fieldSchema.maximum;

        // If it's a number that ends in .0
        if (floatPattern.test(e.target.value)) {
          if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
            updatedSettings[section][key] = e.target.value.trim();
          } else {
            e.target.value = updatedSettings[section][key];
            alert(`This input should be a number between ${minValue} and ${maxValue}!`);
          }
        }

        // If it's any other kind of number
        else if (!Number.isNaN(Number(e.target.value))) {
          if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
            updatedSettings[section][key] = Number(e.target.value);
          } else {
            e.target.value = updatedSettings[section][key];
            alert(`This input should be a number between ${minValue} and ${maxValue}!`);
          }
        }

        // If it's not a number
        else {
          e.target.value = updatedSettings[section][key];
          alert(`This input should be a number between ${minValue} and ${maxValue}!`);
        }
      }

      // String
      else if (requiredType === 'string') {
        // String has a pattern (for AOIs)
        if (fieldSchema.pattern) {
          const stringPattern = new RegExp(fieldSchema.pattern, 'i');

          if (stringPattern.test(e.target.value)) {
            const chosenPoints = Object.values(extractPoints(e.target.value.trim())).map(({ lng, lat }) => [lng, lat]);
            const stackAOIPoints = Object.values(extractPoints(chosenStackAOI)).map(({ lng, lat }) => [lng, lat]);

            if (checkPolygonOverlap(chosenPoints, stackAOIPoints)) {
              updatedSettings[section][key] = e.target.value.trim();
            } else {
              e.target.value = updatedSettings[section][key];
              alert('The chosen AOI does not overlap with the stack AOI!');
            }
          } else {
            e.target.value = updatedSettings[section][key];
            alert('This input does not match the required pattern!');
          }
        }

        // String has some possible values (coherence_type, siblings_test)
        else if (fieldSchema.enum) {
          if (fieldSchema.enum.includes(e.target.value)) {
            updatedSettings[section][key] = e.target.value.trim();
          } else {
            e.target.value = updatedSettings[section][key];
            alert(`This input does not match the accepted values (${fieldSchema.enum.join(', ')})!`);
          }
        }

        // String doesn't have a pattern or possible values (Don't think this will ever be the case)
        else {
          alert('Something went wrong while validating the input!');
        }
      }
    }
  }
  // The input is empty ("")
  else {
    // The input is allowed to be null -> null
    if ((Array.isArray(fieldSchema.type) && fieldSchema.type.includes('null')) || fieldSchema.type === 'null') {
      updatedSettings[section][key] = null;
      e.target.value = 'null';
    }
    // The input is not allowed to be null -> reset to previous value
    else {
      e.target.value = updatedSettings[section][key];
      alert('This input should not be null!');
    }
  }

  setNetworkSettings(updatedSettings);
}

/**
  * Handler for changing a densification-related setting
  * @param e - the event that triggered the change (from this event we can extract the value of the input)
  * @param keys - the keys of the setting that was changed (e.g. ["densification_ps", "defo_estimation", "ps_settings"])
  * @param chosenStackAOI - the AOI of the chosen stack
  * @param groundResolution - the project.ground_resolution setting for the current project, used for validation
  * @param densificationSettings - the current densification settings
  * @param setDensificationSettings - the function that updates the densification settings state
  */
export function handleDensificationSettingsChange(e, keys, chosenStackAOI, groundResolution, densificationSettings, setDensificationSettings) {
  const updatedSettings = { ...densificationSettings };
  const propertyNames = keys.split('.');

  // Get schema for the field
  let fieldSchema = schema.properties;
  for (let i = 0; i < propertyNames.length - 1; i++) {
    fieldSchema = fieldSchema[propertyNames[i]].properties;
  }
  fieldSchema = fieldSchema[propertyNames[propertyNames.length - 1]];

  let currentObject = updatedSettings;
  for (let i = 0; i < propertyNames.length - 1; i++) {
    const propertyName = propertyNames[i];
    currentObject = currentObject[propertyName];
  }

  const targetPropertyName = propertyNames[propertyNames.length - 1];

  // The input is not empty
  if (e.target.value.trim().length !== 0) {
    // If the input is null
    if (e.target.value === 'null') {
      // Input can be null
      if (Array.isArray(fieldSchema.type) && fieldSchema.type.includes('null')) {
        currentObject[targetPropertyName] = null;
      }

      // Input can't be null
      else {
        e.target.value = currentObject[targetPropertyName];
        alert('This input can not be null!');
      }
    } else if (e.target.value !== 'null') {
      const floatPattern = /^\d+\.0$/; // matches numbers ending in .0
      const requiredType = Array.isArray(fieldSchema.type) ? fieldSchema.type.filter((type) => type !== 'null')[0] : fieldSchema.type;

      // Boolean
      if (requiredType === 'boolean') {
        if (e.target.value === 'true' || e.target.value === 'false') {
          currentObject[targetPropertyName] = e.target.value === 'true';
        } else {
          e.target.value = currentObject[targetPropertyName];
          alert('This input should be a boolean!');
        }
      } else if (requiredType === 'integer') {
        const minValue = fieldSchema.minimum;
        const maxValue = fieldSchema.maximum;

        if (targetPropertyName === 'min_number_of_siblings') {
          const { radius } = densificationSettings.densification_ods.defo_estimation.ods_settings;

          const pixelLineArray = groundResolution.match(/\d+/g); // Matches sequences of one or more digits
          const groundLineSpacing = pixelLineArray[1];
          const ground_pixel_spacing = pixelLineArray[0];

          const scale = (winSize, factor) => {
            winSize = parseInt(factor * winSize);
            if (winSize % 2 === 0) winSize += 1;

            return winSize;
          };

          const winLine = scale(2 * parseInt(radius), 1 / parseInt(groundLineSpacing));
          const winPixel = scale(2 * parseInt(radius), 1 / parseInt(ground_pixel_spacing));

          console.log('winLine: ', winLine);
          console.log('winPixel: ', winPixel);

          if (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= winLine * winPixel) {
            currentObject[targetPropertyName] = parseInt(e.target.value);
            e.target.value = parseInt(e.target.value);
          } else {
            e.target.value = currentObject[targetPropertyName];
            alert(`This input should be a number between 1 and ${winLine * winPixel}! This range is determined by the radius and the ground resolution fields!`);
          }
        } else {
          // If it's a number that ends in .0 -> transform to integer
          if (floatPattern.test(e.target.value)) {
            if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
              currentObject[targetPropertyName] = Number(e.target.value);
              e.target.value = Number(e.target.value);
            } else {
              e.target.value = currentObject[targetPropertyName];
              alert(`This input should be a number between ${minValue} and ${maxValue}!`);
            }
          }

          // If it is any other number
          else if (!Number.isNaN(Number(e.target.value))) {
            if (Number.isInteger(Number(e.target.value))) {
              if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
                currentObject[targetPropertyName] = Number(e.target.value);
              } else {
                e.target.value = currentObject[targetPropertyName];
                alert(`This input should be a number between ${minValue} and ${maxValue}!`);
              }
            } else {
              e.target.value = currentObject[targetPropertyName];
              alert('This input should be an integer!');
            }
          }

          // If it's not a number
          else {
            e.target.value = currentObject[targetPropertyName];
            alert(`This input should be an integer between ${minValue} and ${maxValue}!`);
          }
        }
      }

      // Number
      else if (requiredType === 'number') {
        const minValue = fieldSchema.minimum;
        const maxValue = fieldSchema.maximum;

        // If it's a number that ends in .0
        if (floatPattern.test(e.target.value)) {
          if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
            currentObject[targetPropertyName] = e.target.value.trim();
          } else {
            e.target.value = currentObject[targetPropertyName];
            alert(`This input should be a number between ${minValue} and ${maxValue}!`);
          }
        }

        // If it's any other kind of number
        else if (!Number.isNaN(Number(e.target.value))) {
          if (Number(e.target.value) >= minValue && Number(e.target.value) <= maxValue) {
            currentObject[targetPropertyName] = Number(e.target.value);
          } else {
            e.target.value = currentObject[targetPropertyName];
            alert(`This input should be a number between ${minValue} and ${maxValue}!`);
          }
        }

        // If it's not a number
        else {
          e.target.value = currentObject[targetPropertyName];
          alert(`This input should be a number between ${minValue} and ${maxValue}!`);
        }
      }

      // String
      else if (requiredType === 'string') {
        // String has a pattern (for AOIs)
        if (fieldSchema.pattern) {
          const stringPattern = new RegExp(fieldSchema.pattern, 'i');

          if (stringPattern.test(e.target.value)) {
            const chosenPoints = Object.values(extractPoints(e.target.value.trim())).map(({ lng, lat }) => [lng, lat]);
            const stackAOIPoints = Object.values(extractPoints(chosenStackAOI)).map(({ lng, lat }) => [lng, lat]);

            if (checkPolygonOverlap(chosenPoints, stackAOIPoints)) {
              currentObject[targetPropertyName] = e.target.value.trim();
            } else {
              e.target.value = currentObject[targetPropertyName];
              alert('The chosen AOI does not overlap with the stack AOI!');
            }
          } else {
            e.target.value = currentObject[targetPropertyName];
            alert('This input does not match the required pattern!');
          }
        }

        // String has some possible values (coherence_type, siblings_test)
        else if (fieldSchema.enum) {
          if (fieldSchema.enum.includes(e.target.value)) {
            currentObject[targetPropertyName] = e.target.value.trim();
          } else {
            e.target.value = currentObject[targetPropertyName];
            alert(`This input does not match the accepted values (${fieldSchema.enum.join(', ')})!`);
          }
        }

        // String doesn't have a pattern or possible values (Don't think this will ever be the case)
        else {
          alert('Something went wrong while validating the input!');
        }
      }
    }
  }
  // The input is empty
  else {
    // Input can be null -> set to null
    if ((Array.isArray(fieldSchema.type) && fieldSchema.type.includes('null')) || fieldSchema.type === 'null') {
      currentObject[targetPropertyName] = null;
      e.target.value = 'null';
    }
    // Input can not be null -> set to previous value
    else {
      e.target.value = currentObject[targetPropertyName];
      alert('This input can not be null!');
    }
  }

  setDensificationSettings(updatedSettings);
}
