import React from 'react';
import axios from 'axios';
import {
  retrieveProjects, retrieveProjectStacks, getAllProgressUrl, getNetworkProgressUrl, getPrepProgressUrl, getDensificationProgressUrl,
  runUrl, killUrl, getProjectAOIsURL, checkSettingsURL
} from './API';
import DetailedError from './errors';

export async function checkProjectSettings(chosenProject) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: chosenProject,
    },
  };

  try {
    const response = await axios.get(checkSettingsURL, options);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error:', error.message);
      throw error;
    }
  }
}

export async function loadProjectAOIs(chosenProject) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: chosenProject,
    },
  };

  try {
    const response = await axios.get(getProjectAOIsURL, options);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error:', error.message);
      throw error;
    }
  }
}

export async function loadProjectStatus(chosenProject) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: chosenProject,
    },
  };

  try {
    const response = await axios.get(getAllProgressUrl, options);
    console.log('PROGRESS: ', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error:', error.message);
      throw error;
    }
  }
}

export async function getPrepProgress(projectName, stackName) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: projectName,
      stack: stackName,
    },
  };

  try {
    const response = await axios.get(getPrepProgressUrl, options);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error:', error.message);
      throw error;
    }
  }
}

export async function getNetworkProgress(projectName, stackName) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: projectName,
      stack: stackName,
    },
  };

  try {
    const response = await axios.get(getNetworkProgressUrl, options);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error:', error.message);
      throw error;
    }
  }
}

export async function getDensificationProgress(projectName, stackName) {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project: projectName,
      stack: stackName,
    },
  };

  try {
    const response = await axios.get(getDensificationProgressUrl, options);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error:', error.message);
      throw error;
    }
  }
}

export async function getAllProjects() {
  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  };

  try {
    console.log('Session storage: ', sessionStorage);
    console.log('TOKEN: ', token);

    const response = await axios.get(retrieveProjects, options);

    if (response.data !== '[]') {
      console.log('PROJECTS: ', response.data);
      return response.data;
    }

    return [];
  } catch (error) {
    console.log(`There was an error while retrieving the projects data: ${error}`);
    return [];
  }
}

export async function getProjectStacks(chosenProject) {
  if (chosenProject === '') return [];

  const token = JSON.parse(sessionStorage.getItem('token'));
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    params: {
      project_name: chosenProject,
    },
  };

  try {
    const response = await axios.get(retrieveProjectStacks, options);

    if (response.data !== '[]') {
      return Object(response.data);
    }

    return [];
  } catch (error) {
    console.log(`There was an error while retrieving the project's stacks: ${error}`);
    return [];
  }
}

export async function runProcess(project, stack, type) {
  try {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const params = {
      project_name: project,
      stack_name: stack,
      process: type,
    };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    const response = await axios.post(runUrl, params, options);
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error in runProcess:', error.message);
      throw error;
    }
  }
}

export async function killProcess(project, stack, type) {
  try {
    const token = JSON.parse(sessionStorage.getItem('token'));
    const params = {
      project_name: project,
      stack_name: stack,
      process: type,
    };

    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
    };

    const response = await axios.post(killUrl, params, options);
    return response;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new DetailedError(error.response.data.error, error.response.data.details);
    } else {
      console.error('Unknown error in killProgress:', error.message);
      throw error;
    }
  }
}
