import * as React from 'react';
import {
  Box, Typography, Tooltip, TextField, Button,
} from '@mui/material';
// Accordion imports
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import schema from '../util/schema.json';
import DateRangePicker from './DateRangePicker';

import { handleDateClick, handleDeselectAllDates, handleSelectAllDates } from '../util/settingsHandlers';

function ProjectSettingsTab({
  projectSettings, dates, setDates, filteredDates, setFilteredDates,
}) {
  return (
    <Accordion sx={{ marginBottom: 2, borderRadius: 2, bgcolor: '#548ac9' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Project Settings</Typography>
      </AccordionSummary>

      <AccordionDetails>
        {JSON.stringify(projectSettings) !== '{}'
          && (
          <Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
              {Object.entries(projectSettings).map(([key, value]) => (

                // If field is anything else -> display normally
                (key !== 'include' && key !== 'exclude')

                      && (
                      <Tooltip
                        title={(
                          <span style={{ whiteSpace: 'pre-line' }}>
                            {`${schema.properties.project.properties[key].description}\n\nThis field is read-only!`}
                          </span>
                            )}
                        placement="top"
                        arrow
                      >
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          label={key === 'name' ? 'schema name' : key}
                          defaultValue={projectSettings[key]}
                          sx={{ gridColumn: 'span 1' }}
                        />
                      </Tooltip>
                      )
              ))}
            </Box>

            <Box>
              <h2>Filter Dates</h2>

              <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>

                <DateRangePicker
                  dates={dates}
                  filteredDates={filteredDates}
                  setFilteredDates={setFilteredDates}
                />

              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  sx={{
                    width: 150,
                    height: 40,
                    marginRight: 15,
                    backgroundColor: 'rgb(16, 52, 88)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgb(28, 71, 117)',
                    },
                    '&:disabled': {
                      backgroundColor: '#7D8387',
                      color: 'rgba(0, 0, 0, 0.5)',
                    },
                  }}
                  onClickCapture={
                    () => handleSelectAllDates(dates, setDates, filteredDates, setFilteredDates)
                  }
                >
                  Select all
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    width: 150,
                    height: 40,
                    backgroundColor: 'rgb(16, 52, 88)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgb(28, 71, 117)',
                    },
                    '&:disabled': {
                      backgroundColor: '#7D8387',
                      color: 'rgba(0, 0, 0, 0.5)',
                    },
                  }}
                  onClickCapture={
                    () => handleDeselectAllDates(dates, setDates, filteredDates, setFilteredDates)
                  }
                >
                  Deselect all
                </Button>
              </Box>

              {
                filteredDates.length > 0 && (
                  <Box>
                    {Object.entries(filteredDates.reduce((groupedDates, { date, checked }) => {
                      const year = date.slice(0, 4);
                      const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
                      const buttonStyles = checked
                        ? {
                          color: 'white', backgroundColor: '#02055A', borderColor: '#02055A', fontWeight: 'bold',
                        }
                        : { color: '#02055A', borderColor: '#02055A', fontWeight: 'normal' };

                      if (!groupedDates.hasOwnProperty(year)) {
                        groupedDates[year] = [];
                      }

                      groupedDates[year].push(
                        <Button
                          key={date}
                          variant="outlined"
                          style={{ ...buttonStyles, margin: '10px' }}
                          onClick={() => handleDateClick(date, setDates, setFilteredDates)}
                        >
                          {formattedDate}
                        </Button>,
                      );

                      return groupedDates;
                    }, {}))
                      .map(([year, dates]) => (
                        <div key={year}>
                          <h2>
                            {' '}
                            {year}
                            {' '}
                          </h2>
                          {dates}
                        </div>
                      ))}
                  </Box>
                )
              }
            </Box>
          </Box>
          )}
      </AccordionDetails>
    </Accordion>
  );
}

export default ProjectSettingsTab;
