const domain = process.env.REACT_APP_FRONTEND || 'http://127.0.0.1:8000/';

const auth = `${domain}auth/`;
const getProjectAoisUrl = `${domain}setup/get_project_aois/`;
const createProjectUrl = `${domain}setup/create_project/`;
const densificationProgressUrl = `${domain}projects/densification_progress/`;
const getProjectsUrl = `${domain}projects/get_all_projects/`;
const getProjectStacksUrl = `${domain}projects/get_project_stacks/`;
const getStacksUrl = `${domain}setup/stacks/`;
const killProcessUrl = `${domain}projects/kill_process/`;
const loadProjectUrl = `${domain}projects/load_project/`;
const networkProgressUrl = `${domain}projects/network_progress/`;
const runProcessUrl = `${domain}projects/run_process/`;
const prepareStackUrl = `${domain}projects/prepare_stack/`;
const users = `${domain}user/users/`;
const prepProgressUrl = `${domain}projects/get_stack_prep_progress/`;
const retrieveProjects = `${domain}setup/retrieve_projects/`;
const retrieveProjectStacks = `${domain}setup/retrieve_project_stacks/`;
const getSettingsUrl = `${domain}setup/get_settings/`;
const saveSettingsUrl = `${domain}setup/save_settings/`;
const getStackAoiUrl = `${domain}setup/retrieve_stack_aoi/`;
const killPrepUrl = `${domain}projects/kill_prep/`;

// New endpoints
const checkSettingsURL = `${domain}launchpad/check_settings/`;
const getProjectAOIsURL = `${domain}launchpad/get_all_project_aois/`;
const getAllProgressUrl = `${domain}launchpad/get_all_progress/`;
const getPrepProgressUrl = `${domain}launchpad/get_prep_progress/`;
const getNetworkProgressUrl = `${domain}launchpad/get_network_progress/`;
const getDensificationProgressUrl = `${domain}launchpad/get_densification_progress/`;

const runUrl = `${domain}launchpad/run/`;
const killUrl = `${domain}launchpad/kill/`;

export {
  auth,
  getProjectAoisUrl,
  createProjectUrl,
  densificationProgressUrl,
  getProjectsUrl,
  getProjectStacksUrl,
  getStacksUrl,
  killProcessUrl,
  loadProjectUrl,
  networkProgressUrl,
  runProcessUrl,
  prepareStackUrl,
  users,
  prepProgressUrl,
  retrieveProjects,
  retrieveProjectStacks,
  getSettingsUrl,
  saveSettingsUrl,
  getStackAoiUrl,
  killPrepUrl,
  checkSettingsURL,
  getProjectAOIsURL,
  getAllProgressUrl,
  getPrepProgressUrl,
  getNetworkProgressUrl,
  getDensificationProgressUrl,
  runUrl,
  killUrl,
};
