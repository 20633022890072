import * as React from 'react';
import '../styles/Settings.css';
import {
  Box, Button, TextField, Typography, Autocomplete, Snackbar, Alert, FormControl, FormGroup, FormControlLabel, Switch,
} from '@mui/material';
import axios from 'axios';

// Accordion imports
import Sidebar from '../components/Sidebar';
import { getSettingsUrl, getStackAoiUrl, saveSettingsUrl } from '../util/API';

// Tabs imports
import ProjectSettingsTab from '../components/ProjectSettingsTab';
import NetworkSettingsTab from '../components/NetworkSettingsTab';
import DensificationSettingsTab from '../components/DensificationSettingsTab';

// Utils
import {
  allWhiteAutocompleteStyle,
  buttonStyle,
  isEmpty,
  copyObject,
  settingsMatch,
  datesMatch,
} from '../util/settingsUtils';
import InactiveTimer from '../components/inactiveTimer';
import logout from '../util/logout';

// Hooks
import useAllProjects from '../hooks/useAllProjects';
import useProjectStacks from '../hooks/useProjectStacks';

const _ = require('lodash');

const drawerWidth = 240;

export default function Settings() {
  const { projects } = useAllProjects();

  const [projectName, setProjectName] = React.useState('');
  const { projectStacks: currentStacks } = useProjectStacks(projectName);
  const [chosenStack, setChosenStack] = React.useState('');
  const [loadedProject, setLoadedProject] = React.useState('');
  const [loadedStack, setLoadedStack] = React.useState('');

  const [copyProject, setCopyProject] = React.useState('');
  const { projectStacks: copyStacks } = useProjectStacks(copyProject);
  const [copyStack, setCopyStack] = React.useState('');

  const [switches, setSwitches] = React.useState({
    copyAois: false,
    copyDates: false,
  });

  const [chosenStackAOI, setChosenStackAOI] = React.useState('');
  const [originalAOI, setOriginalAOI] = React.useState('');

  const [projectSettings, setProjectSettings] = React.useState({});
  const [networkSettings, setNetworkSettings] = React.useState({});
  const [densificationSettings, setDensificationSettings] = React.useState({});

  // Dates and logs
  const [dates, setDates] = React.useState([]);
  const [filteredDates, setFilteredDates] = React.useState([]);
  const [logs, setLogs] = React.useState('');
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const [shouldSaveSettings, setShouldSaveSettings] = React.useState(false);

  const groundResolution = projectSettings.ground_resolution || null;
  const token = JSON.parse(sessionStorage.getItem('token'));

  /**
   * Handles the change of the switches in the settings page.
   * @param {Event} event The event that triggered the change.
   */
  const handleSwitchChange = (event) => {
    setSwitches({
      ...switches,
      [event.target.name]: event.target.checked,
    });
  };

  /**
   * Checks for inactivity every 60 minutes.
   */
  React.useEffect(() => {
    const timer = new InactiveTimer({
      timeout: 3600,
      onTimeout: () => {
        logout(); // If timer expires when the user is in the app
      },
    });

    return () => {
      timer.cleanup();
    };
  }, []);

  /**
   * Retrieve the AOI for a specific stack when one is picked
   */
  React.useEffect(() => {
    if (chosenStack !== '') {
      axios.get(
        getStackAoiUrl,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
          params: {
            stack: chosenStack,
          },
        },
      )
        .then((response) => {
          if (response.data.status === 'success') {
            setChosenStackAOI(response.data.response);
          } else {
            console.log(`There was an error while retrieving the stack AOI data: ${response.data.response}`);
          }
        })
        .catch((error) => {
          console.log(`There was an error while retrieving the stack AOI data: ${error}`);
        });
    }
  }, [chosenStack]);

  /**
   * Combines all settings into a single object and sends a request to the backend to save them
   */
  const saveSettings = () => {
    // Save the dates to the include and exclude arrays
    const includedDates = [];
    const excludedDates = [];

    dates.forEach(({ date, checked }) => {
      if (checked) {
        includedDates.push(date);
      } else {
        excludedDates.push(date);
      }
    });

    // sort includedDates and excludedDates
    includedDates.sort();
    excludedDates.sort();

    const newProjectSettings = {
      ...projectSettings,
      include: includedDates.length > 0 ? includedDates : null,
      exclude: excludedDates.length > 0 ? excludedDates : null,
    };

    const newSettings = {
      ...{ project: newProjectSettings },
      ...{ network: networkSettings },
      ...{ densification_ps: densificationSettings.densification_ps },
      ...{ densification_intermittent_ps: densificationSettings.densification_intermittent_ps },
      ...{ densification_ods: densificationSettings.densification_ods },
    };

    console.log('NEW SETTINGS: ', newSettings);

    setOriginalAOI(newSettings.network.general.aoi);

    axios
      .post(
        saveSettingsUrl,
        {
          project_name: projectName,
          stack: chosenStack,
          settings: newSettings,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
        },
      )
      .then((response) => {
        if (response.data.status === 'success') {
          setLogs(`${logs}\nSettings for ${projectName} with the stack ${chosenStack} saved successfully!\n`);
        } else {
          setLogs(`${logs}\nSomething went wrong while saving the settings for ${projectName} with the stack ${chosenStack}.\n`);
        }

        console.log(response);
      })
      .catch((error) => {
        console.log(error);
        setLogs(`${logs}\nSomething went wrong while saving the settings for ${projectName} with the stack ${chosenStack}.\n`);
      });
  };

  /**
   * Retrieve the settings for a specific project and stack when the load button is pressed
   */
  const loadProjectSettings = () => {
    setProjectSettings({});
    setNetworkSettings({});
    setDensificationSettings({});
    setDates([]);

    axios
      .get(
        getSettingsUrl,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
          params: {
            project_name: projectName,
            stack: chosenStack,
          },
        },
      )
      .then((response) => {
        if (response.data.status === 'success') {
          const { project, network, ...dens } = response.data.response;

          setOriginalAOI(response.data.response.network.general.aoi);
          setProjectSettings(project);
          setNetworkSettings(network);
          setDensificationSettings(dens);

          // Create the array of dates, with the checked status
          const combinedDates = [];

          if (project.include !== null) {
            for (const date of project.include) {
              combinedDates.push({ date, checked: true });
            }
          }

          if (project.exclude !== null) {
            for (const date of project.exclude) {
              combinedDates.push({ date, checked: false });
            }
          }

          setDates(combinedDates.sort((a, b) => ((a.date > b.date) ? 1 : -1)));
          setFilteredDates(combinedDates.sort((a, b) => ((a.date > b.date) ? 1 : -1)));

          setLogs(`${logs}\nSettings loaded successfully for ${projectName} with the stack ${chosenStack}!\n`);
          setLoadedProject(projectName);
          setLoadedStack(chosenStack);
        } else {
          setLogs(`${logs}\nSomething went wrong while loading the settings for ${projectName} with the stack ${chosenStack}.\n`);
        }
      })
      .catch((error) => {
        console.log(error);
        setLogs(`${logs}\nSomething went wrong while loading the settings for ${projectName} with the stack ${chosenStack}.\n`);
      });
  };

  /**
   * Saves the new settings after they have been updated.
   */
  React.useEffect(() => {
    if (shouldSaveSettings) {
      saveSettings();
      setShouldSaveSettings(false);
    }
  }, [shouldSaveSettings]);

  /**
   * Copies the settings from the selected project and stack to the current project and stack
   */
  const copySettings = async () => {
    try {
      const response = await axios.get(
        getSettingsUrl,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
          params: {
            project_name: copyProject,
            stack: copyStack,
          },
        },
      );

      if (response.data.status === 'success') {
        const { project, network, ...dens } = response.data.response;

        if (settingsMatch(networkSettings, network) && settingsMatch(densificationSettings, dens)) {
          const networkCopy = copyObject(network, networkSettings, switches.copyAois);
          console.log('networkCopy: ', networkCopy);
          console.log('current settings: ', networkSettings);
          console.log('new settings: ', network);

          const densificationCopy = copyObject(dens, densificationSettings, switches.copyAois);

          if (switches.copyDates) {
            const existingDates = [
              ...(projectSettings.include || []),
              ...(projectSettings.exclude || []),
            ].sort();

            const newDates = [
              ...(project.include || []),
              ...(project.exclude || []),
            ].sort();

            if (datesMatch(existingDates, newDates)) {
              setDates((prevDates) => prevDates.map((d) => {
                if (project.include.includes(d.date)) {
                  return { ...d, checked: true };
                }
                return { ...d, checked: false };
              }));
            } else {
              alert(`Dates could not be copied from ${copyProject} with the stack ${copyStack}. The two projects are using different images.
              \nThe rest of the settings will be copied\n`);
            }
          }

          setNetworkSettings(
            switches.copyAois ? networkCopy
              : {
                ...networkCopy,
                ...{ general: { aoi: networkSettings.general.aoi } },
              },
          );

          setDensificationSettings(
            switches.copyAois ? { ...densificationCopy }
              : {
                ...densificationCopy,
                densification_ps: {
                  ...densificationCopy.densification_ps,
                  general: {
                    ...densificationCopy.densification_ps.general,
                    aoi: densificationSettings.densification_ps.general.aoi,
                  },
                },
                densification_intermittent_ps: {
                  ...densificationCopy.densification_intermittent_ps,
                  general: {
                    ...densificationCopy.densification_intermittent_ps.general,
                    aoi: densificationSettings.densification_intermittent_ps.general.aoi,
                  },
                },
                densification_ods: {
                  ...densificationCopy.densification_ods,
                  general: {
                    ...densificationCopy.densification_ods.general,
                    aoi: densificationSettings.densification_ods.general.aoi,
                  },
                },
              },
          );

          // Show a confirmation message after copying the settings for 2 seconds
          setShowConfirmation(true);

          setTimeout(() => {
            setShowConfirmation(false);
            setShouldSaveSettings(true);
          }, 5000);

          setLogs(`${logs}\nSettings copied successfully from ${copyProject} with the stack ${copyStack}! The settings will be saved shortly.\nYou need to reload the project settings to see the changes.\n`);
        } else {
          setLogs(`${logs}\nSettings could not be copied from ${copyProject} with the stack ${copyStack}. The two settings objects have different keys.\n`);
        }
      } else {
        setLogs(`${logs}\nSomething went wrong while copying the settings from ${copyProject} with the stack ${copyStack}.\n`);
      }
    } catch (error) {
      console.error(error);
      setLogs(`${logs}\nSomething went wrong while copying the settings from ${copyProject} with the stack ${copyStack}.\n`);
    }
  };

  /**
   * UI for the settings page
   */
  return (
    <div id="settings-background">
      <Box sx={{ display: 'flex' }}>
        {/* Sidebar component. */}
        <Sidebar />

        {/* Main content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1, p: 3, m: 1, width: { sm: `calc(100% - ${drawerWidth}px)` },
            // gridTemplateColumns: 'repeat(5, 1fr)'
          }}
        >
          <h1>Settings</h1>

          {/* Project selector */}

          { projects && projects.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={projects}
              autoHighlight
              sx={{ width: 350, marginTop: '10px' }}
              onChange={(event, value) => {
                if (value !== null) {
                  setProjectName(value);
                } else {
                  setProjectName('');
                  setProjectSettings({});
                  setNetworkSettings({});
                  setDensificationSettings({});
                }
                setChosenStack('');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project name"
                  InputLabelProps={{
                    shrink: params.inputProps.value || projectName !== '', // Apply the shrink effect when there is a selected value or user input
                    style: {
                      color: 'white', // Set the label text color to white
                    },
                  }}
                  sx={allWhiteAutocompleteStyle}
                />
              )}
            />
          )}

          {/* Project stack selector */}
          { currentStacks && currentStacks.length > 0 && (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={currentStacks}
              autoHighlight
              sx={{ width: 350, marginTop: '10px' }}
              onChange={(event, value) => {
                if (value !== null) {
                  setChosenStack(value.name);
                } else {
                  setChosenStack('');
                  setProjectSettings({});
                  setNetworkSettings({});
                  setDensificationSettings({});
                }
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value}
              value={currentStacks.find((stack) => stack.name === chosenStack) || null}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    color: option.in_hetzner ? 'inherit' : 'red',
                  }}
                >
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project stack"
                  InputLabelProps={{
                    shrink: params.inputProps.value || chosenStack !== '',
                    style: {
                      color: 'white',
                    },
                  }}
                  sx={allWhiteAutocompleteStyle}
                />
              )}
            />
          )}

          { /* Project load button */}
          <Box>
            <Button
              variant="contained"
              disabled={projectName === '' || chosenStack === ''}
              sx={buttonStyle}
              onClickCapture={() => {
                loadProjectSettings();
              }}
            >
              Load Project
            </Button>
          </Box>

          { /* Section for copying settings */ }
          <Box sx={{
            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px', marginBottom: '20px',
          }}
          >

            <Typography sx={{ marginRight: '10px' }}>
              Copy from:
            </Typography>

            { projects && projects.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={projects}
                autoHighlight
                sx={{ width: 350, marginRight: '10px' }}
                onChange={(event, value) => {
                  setCopyProject(value == null ? '' : value);
                  setCopyStack('');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project name"
                    InputLabelProps={{
                      shrink: params.inputProps.value || copyProject !== '', // Apply the shrink effect when there is a selected value or user input
                      style: {
                        color: 'white', // Set the label text color to white
                      },
                    }}
                    sx={allWhiteAutocompleteStyle}
                  />
                )}
              />
            )}

            { copyStacks && copyStacks.length > 0 && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={copyStacks}
                autoHighlight
                sx={{ width: 350, marginRight: '10px' }}
                onChange={(event, value) => {
                  setCopyStack(value == null ? '' : value.name);
                }}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.name === value}
                value={copyStacks.find((stack) => stack.name === copyStack) || null}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      color: option.in_hetzner ? 'inherit' : 'red',
                    }}
                  >
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project stack"
                    InputLabelProps={{
                      shrink: params.inputProps.value || copyStack !== '',
                      style: {
                        color: 'white',
                      },
                    }}
                    sx={allWhiteAutocompleteStyle}
                  />
                )}
              />
            )}

            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={switches.copyAois} onChange={handleSwitchChange} name="copyAois" />
                  }
                  label="Copy AOIs"
                />
                <FormControlLabel
                  control={
                    <Switch checked={switches.copyDates} onChange={handleSwitchChange} name="copyDates" />
                  }
                  label="Copy Dates"
                />
              </FormGroup>
            </FormControl>

            <Button
              variant="contained"
              disabled={
                projectName === ''
                || projectName !== loadedProject
                || chosenStack === ''
                || chosenStack !== loadedStack
                || copyProject === ''
                || copyStack === ''
                || isEmpty(projectSettings)
                || isEmpty(networkSettings)
                || isEmpty(densificationSettings)
              }
              sx={buttonStyle}
              onClickCapture={() => {
                copySettings();
              }}
            >
              Copy settings
            </Button>

          </Box>

          { /* Section for project settings */}
          <ProjectSettingsTab
            projectSettings={projectSettings}
            dates={dates}
            setDates={setDates}
            filteredDates={filteredDates}
            setFilteredDates={setFilteredDates}
          />

          { /* Section for network settings */}
          <NetworkSettingsTab
            networkSettings={networkSettings}
            setNetworkSettings={setNetworkSettings}
            originalAOI={originalAOI}
            chosenStackAOI={chosenStackAOI}
          />

          { /* Section for densification settings */}

          <DensificationSettingsTab
            groundResolution={groundResolution}
            densificationSettings={densificationSettings}
            setDensificationSettings={setDensificationSettings}
            chosenStackAOI={chosenStackAOI}
          />

          <Button
            variant="contained"
            sx={buttonStyle}
            disabled={
              _.isEmpty(projectSettings)
              || _.isEmpty(networkSettings)
              || _.isEmpty(densificationSettings)
              || projectName === ''
              || chosenStack === ''
              || projectName !== loadedProject
              || chosenStack !== loadedStack
            }
            onClickCapture={() => {
              saveSettings();
            }}
          >
            Save settings
          </Button>

          <h1> Output logs </h1>
          <Typography style={{ whiteSpace: 'pre-line' }}>
            {logs}
          </Typography>

          {showConfirmation && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={showConfirmation}
              key="bottomright"
            >
              <Alert severity="success" sx={{ width: '100%' }}>
                Settings copied successfully! The settings will be saved shortly.
                <br />
                The changes will be visible after reloading the project settings.
              </Alert>
            </Snackbar>
          )}
        </Box>
      </Box>
    </div>
  );
}
