import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer, TileLayer, Polygon,
} from 'react-leaflet';
import { v4 as uuidv4 } from 'uuid';
import {
  Box, TextField, Button, Tab, Tabs,
} from '@mui/material';
import wellknown from 'wellknown';
import {
  aoiCenter, calculateMaxBounds, enclosedArea, extractWKT, extractPoints,
} from '../util/geometry';

import DraggableMarker from './DraggableMarker';
import StackSelector from './StackSelector';
import MarkerHandler from './MarkerHandler';

function MapComponent({
  networkAoi,
  setNetworkAoi,
  densificationAoi,
  setDensificationAoi,
  networkAoiError,
  setNetworkAoiError,
  densificationAoiError,
  setDensificationAoiError,
  intersectingStacks,
  setIntersectingStacks,
}) {
  console.log('Network aoi: ', networkAoi);
  console.log('Densification aoi: ', densificationAoi);

  // Chosen AOI state variables
  const [networkMarkers, setNetworkMarkers] = useState([]);
  const [densificationMarkers, setDensificationMarkers] = useState([]);
  const [isPopupClicked, setPopupClicked] = useState(false);

  // New state for tracking network marker removal
  const [isNetworkMarkerRemoved, setIsNetworkMarkerRemoved] = useState(false);
  // New state for tracking densification marker removal
  const [isDensificationMarkerRemoved, setIsDensificationMarkerRemoved] = useState(false);

  // Stack AOI state variables
  const [center, setCenter] = useState([30, 0]);
  const [maxBounds, setMaxBounds] = useState([[-100, -200], [100, 200]]);
  const mapRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(0);

  /**
   * Resets the map to its original form, when the chosen stack is deleted.
   */
  const resetMap = () => {
    setCenter([30, 0]);
    setMaxBounds([[-100, -200], [100, 200]]);
    if (mapRef.current) {
      mapRef.current.setView([30, 0], 1);
    }
  };

  /**
   * Calculates the default densification suggestion based on the chosen network AOI.
   */
  const generateDensificationAOI = () => {
    if (networkMarkers.length >= 3) {
      const latLngCoordinates = networkMarkers.map(({ lat, lng }) => [lat, lng]);
      const smallerPolygon = enclosedArea(latLngCoordinates);

      const densificationDefaultMarkers = smallerPolygon.map(([lat, lng], index) => ({
        id: uuidv4(),
        lat,
        lng,
      }));

      setDensificationMarkers(densificationDefaultMarkers);
      setDensificationAoi(extractWKT(densificationDefaultMarkers));
    } else {
      setDensificationMarkers([]);
      setDensificationAoi('POLYGON(())');
    }
  };

  /**
   * Updates the position and zoom of the map based on the selected stack AOIs.
   */
  useEffect(() => {
    const selectedStacks = intersectingStacks.filter(([name, aoi, satellite, selected]) => selected);

    if (selectedStacks.length > 0) {
      // get the aois of all the selected stacks
      const stackPoints = selectedStacks.map(([name, aoi, satellite, selected]) => extractPoints(aoi));

      const stackPointsFlat = stackPoints.flatMap((stack) => stack.map((point) => [point.lat, point.lng]));
      setCenter(aoiCenter(stackPointsFlat));

      if (mapRef.current) {
        if (stackPointsFlat.length > 0) {
          mapRef.current.fitBounds(calculateMaxBounds(stackPointsFlat));
        } else {
          mapRef.current.setView(center, 1);
        }
      } else {
        resetMap();
      }
    }
  }, [intersectingStacks]);

  /**
   * Handles the removal of a marker from the map.
   * @param {uuidv4} id - The id of the marker to be removed.
   * @param {0|1} tab - The tab from which to remove the marker. 0 for network, 1 for densification.
   */
  const removeMarker = (id, tab) => {
    if (tab === 'network') {
      setNetworkMarkers(networkMarkers.filter((marker) => marker.id !== id));
      setIsNetworkMarkerRemoved(true); // Set the isNetworkMarkerRemoved state to true
    } else if (tab === 'densification') {
      setDensificationMarkers(densificationMarkers.filter((marker) => marker.id !== id));
      setIsDensificationMarkerRemoved(true); // Set the isDensificationMarkerRemoved state to true
    }
  };

  /**
   * Clears the markers and AOI of the chosen tab.
   * @param {0|1} tab - The tab to be cleared. 0 for network, 1 for densification.
   */
  const handleClearMap = (tab) => {
    if (tab === 'network') {
      setNetworkMarkers([]);
      setDensificationMarkers([]);
      setNetworkAoi('');
      setDensificationAoi('');
    } else if (tab === 'densification') {
      setDensificationMarkers([]);
      setDensificationAoi('');
    }

    resetMap();
  };

  /**
   * Parses and validates an AOI string from the text fields.
   * @param {String} wktString - the AOI string in the TextField.
   * @returns {Array<Object>} - an array containing an object with latitute, longitude and id for each individual point in the AOI.
   */
  const validateAOI = (wktString) => {
    try {
      const cleanWKT = wktString.replace(/\s+/g, ' ').trim();
      const wkt = wellknown.parse(cleanWKT);

      if (wkt && (wkt.type === 'Point' || wkt.type === 'LineString' || wkt.type === 'Polygon')) {
        let coordinates;

        if (wkt.type === 'Point') {
          coordinates = [wkt.coordinates];
        } else if (wkt.type === 'LineString') {
          coordinates = wkt.coordinates;
        } else if (wkt.type === 'Polygon') {
          coordinates = wkt.coordinates[0]; // Use the outer ring
        }

        return coordinates.map(([lng, lat]) => ({
          id: uuidv4(),
          lat,
          lng,
        }));
      }
    } catch (error) {
      console.error('Error parsing WKT:', error);
    }

    return null;
  };

  /**
   * Verifies the network AOI inputs.
   */
  useEffect(() => {
    const parsedCoordinates = validateAOI(networkAoi);

    if (parsedCoordinates) {
      setNetworkAoiError(false);
      setNetworkMarkers(parsedCoordinates);
    } else {
      setNetworkAoiError(true);
    }
  }, [networkAoi]);

  /**
   * Verifies the densification AOI inputs.
   */
  useEffect(() => {
    const parsedCoordinates = validateAOI(densificationAoi);

    if (parsedCoordinates) {
      setDensificationAoiError(false);
      setDensificationMarkers(parsedCoordinates);
    } else {
      setDensificationAoiError(true);
    }
  }, [densificationAoi]);

  /**
   * Updates the aoi input based on the selected tab.
   * @param {String} input - the input taken from the text field.
   * @param {0|1} tab - the chosen tab. 0 for network, 1 for densification.
   */
  const updateAOIInput = (input, tab) => {
    if (tab === 0) {
      setNetworkAoi(input);
    } else if (tab === 1) {
      setDensificationAoi(input);
    }
  };

  const networkPolygonPositions = networkMarkers.map(
    (marker) => [marker.lat, marker.lng],
  );
  const densificationPolygonPositions = densificationMarkers.map(
    (marker) => [marker.lat, marker.lng],
  );

  return (
    <Box
      sx={{
        backgroundColor: '#1E1E1E',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={(event, newValue) => setSelectedTab(newValue)}
        sx={{ marginBottom: '16px', borderRadius: '40px' }}
        centered
        variant="fullWidth"
        TabIndicatorProps={{
          style: {
            display: 'none', // Remove the indicator line
          },
        }}
      >
        <Tab
          label="Network"
          sx={{
            color: 'white', // Set the font color to white
            '&.Mui-selected': {
              backgroundColor: 'rgb(16, 52, 88)',
              color: 'white', // Set the font color to white for the selected tab
            },
          }}
        />
        <Tab
          disabled={networkMarkers.length < 3}
          label="Densification"
          sx={{
            color: 'white', // Set the font color to white
            '&.Mui-selected': {
              backgroundColor: 'rgb(16, 52, 88)',
              color: 'white', // Set the font color to white for the selected tab
            },
            '&.Mui-disabled': {
              color: 'grey',
            },
          }}
        />
      </Tabs>

      {/* Network Tab */}
      {selectedTab === 0 && (
        <Box
          sx={{
            backgroundColor: '#1E1E1E',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'row',
            padding: '16px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            <MapContainer
              ref={mapRef}
              style={{ height: 350, width: 500, borderRadius: '15px' }}
              center={center}
              zoom={1}
              scrollWheelZoom
              zoomControl={false}
              maxBounds={maxBounds}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {/* Draw the AOI of the chosen stacks */}
              {intersectingStacks.map(([name, aoi, satellite, selected]) => {
                if (selected) {
                  return <Polygon positions={extractPoints(aoi)} color="green" fillOpacity={0} />;
                }
              })}

              {/* Draw the AOI of the densification */}
              {densificationMarkers.length >= 3 && <Polygon positions={densificationPolygonPositions} color="blue" fillOpacity={0} />}

              {networkMarkers.map((marker) => (
                <DraggableMarker
                  key={marker.id}
                  position={[marker.lat, marker.lng]}
                  onDragEnd={(newPosition) => {
                    const updatedMarkers = networkMarkers.map((m) => {
                      if (m.id === marker.id) {
                        return {
                          ...m,
                          lat: newPosition.lat,
                          lng: newPosition.lng,
                        };
                      }
                      return m;
                    });
                    setNetworkMarkers(updatedMarkers);
                  }}
                  onRemove={() => removeMarker(marker.id, 'network')}
                />
              ))}

              {networkMarkers.length >= 3 && <Polygon positions={networkPolygonPositions} color="red" />}

              <MarkerHandler
                markers={networkMarkers}
                setMarkers={setNetworkMarkers}
                setAoi={setNetworkAoi}
                isPopupClicked={isPopupClicked}
                setPopupClicked={setPopupClicked}
                isMarkerRemoved={isNetworkMarkerRemoved}
                setIsMarkerRemoved={setIsNetworkMarkerRemoved}
              />
            </MapContainer>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '7%',
              justifyContent: 'center',
              alignItems: 'center',
              width: '500px',
            }}
          >
            <TextField
              label="Network AOI (WKT)"
              variant="outlined"
              error={networkAoiError}
              helperText={networkAoiError && 'Invalid format'}
              multiline
              rows={8}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Set the outline color to white
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Set the outline color to white on hover
                  },
                  '&.Mui-error fieldset': {
                    borderColor: 'red', // Set the outline color to red for the error state
                  },
                },
                '& label.Mui-focused': {
                  color: 'white', // Set the label color to white when focused
                },
                '& .MuiInputBase-input': {
                  color: 'white', // Set the input text color to white
                },
              }}
              InputProps={{
                style: {
                  color: 'white', // Set the text color to white
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white', // Set the label color to white
                },
              }}
              value={networkAoi}
              onChange={(event) => updateAOIInput(event.target.value, 0)}
            />

            <Button
              variant="contained"
              sx={{
                width: '100%',
                marginTop: '16px',
                backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
                color: 'white', // Enabled text color
                '&:hover': {
                  backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
                },
              }}
              onClick={() => generateDensificationAOI()}
            >
              Generate (small) Densification AOI
            </Button>

            <Button
              variant="contained"
              sx={{
                width: '100%',
                marginTop: '16px',
                backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
                color: 'white', // Enabled text color
                '&:hover': {
                  backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
                },
              }}
              onClick={() => handleClearMap('network')}
            >
              Clear Network AOI
            </Button>
          </Box>
        </Box>
      )}

      {/* Densification Tab */}
      {selectedTab === 1 && (
        <Box
          sx={{
            backgroundColor: '#1E1E1E',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'row',
            padding: '16px',
            justifyContent: 'center',
          }}
        >
          <Box>
            <MapContainer
              ref={mapRef}
              style={{ height: 350, width: 500, borderRadius: '15px' }}
              center={center}
              zoom={1}
              scrollWheelZoom
              zoomControl={false}
              maxBounds={maxBounds}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {/* Draw the AOI of the chosen stacks */}
              {intersectingStacks.map(([name, aoi, satellite, selected]) => {
                if (selected) {
                  return <Polygon positions={extractPoints(aoi)} color="green" />;
                }
              })}

              {/* Draw the AOI of the network */}
              <Polygon positions={networkPolygonPositions} color="blue" fillOpacity={0} />

              {densificationMarkers.map((marker) => (
                <DraggableMarker
                  key={marker.id}
                  position={[marker.lat, marker.lng]}
                  onDragEnd={(newPosition) => {
                    const updatedMarkers = densificationMarkers.map((m) => {
                      if (m.id === marker.id) {
                        return {
                          ...m,
                          lat: newPosition.lat,
                          lng: newPosition.lng,
                        };
                      }
                      return m;
                    });
                    setDensificationMarkers(updatedMarkers);
                  }}
                  onRemove={() => removeMarker(marker.id, 'densification')}
                />
              ))}

              {densificationMarkers.length >= 3 && <Polygon positions={densificationPolygonPositions} color="red" />}

              <MarkerHandler
                markers={densificationMarkers}
                setMarkers={setDensificationMarkers}
                setAoi={setDensificationAoi}
                isPopupClicked={isPopupClicked}
                setPopupClicked={setPopupClicked}
                isMarkerRemoved={isDensificationMarkerRemoved}
                setIsMarkerRemoved={setIsDensificationMarkerRemoved}
              />
            </MapContainer>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '7%',
              justifyContent: 'center',
              width: '500px',
            }}
          >
            <TextField
              label="Densification AOI (WKT)"
              variant="outlined"
              error={densificationAoiError}
              helperText={densificationAoiError && 'Invalid format'}
              multiline
              rows={8}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white', // Set the outline color to white
                  },
                  '&:hover fieldset': {
                    borderColor: 'white', // Set the outline color to white on hover
                  },
                  '&.Mui-error fieldset': {
                    borderColor: 'red', // Set the outline color to red for the error state
                  },
                },
                '& label.Mui-focused': {
                  color: 'white', // Set the label color to white when focused
                },
                '& .MuiInputBase-input': {
                  color: 'white', // Set the input text color to white
                },
              }}
              InputProps={{
                style: {
                  color: 'white', // Set the text color to white
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white', // Set the label color to white
                },
              }}
              value={densificationAoi}
              onChange={(event) => updateAOIInput(event.target.value, 1)}
            />

            <Button
              variant="contained"
              sx={{
                width: '100%',
                marginTop: '16px',
                backgroundColor: 'rgb(16, 52, 88)', // Enabled background color
                color: 'white', // Enabled text color
                '&:hover': {
                  backgroundColor: 'rgb(28, 71, 117)', // Enabled background color on hover
                },
              }}
              onClick={() => handleClearMap('densification')}
            >
              Clear Densification AOI
            </Button>
          </Box>
        </Box>
      )}

      <StackSelector intersectingStacks={intersectingStacks} setIntersectingStacks={setIntersectingStacks} />

    </Box>
  );
}

export default MapComponent;
